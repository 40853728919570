/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import DateFormatterComponent from 'components/formatters/date';
import NumberFormatterComponent from 'components/formatters/number';
import AmountFormatterComponent from 'components/formatters/amount'
import IntlMessageComponent from 'components/formatters/intlMessage';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

import CompanyFixContract from 'models/companies/companyFixContract';

type CompanyFixContractCardViewComponentProps = {
  fixContract: CompanyFixContract;
  isConfirming: boolean;
  reloadfixContracts: () => void;
}

export default function CompanyFixContractCardViewComponent(props: CompanyFixContractCardViewComponentProps) {
  const activeCompany = useSelector(state => state.auth.user.activeCompany);

  return (
    <React.Fragment>
      <PageSubTitleViewComponent id={props.isConfirming ? "page.fixcontractconfirming.title" : "page.fixContract.title"} />

      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <b><IntlMessageComponent id="page.fixContract.startdate.label" /></b>
        </Grid>
        <Grid item xs={6}>
          <DateFormatterComponent date={props.fixContract.startDate} />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <b><IntlMessageComponent id="page.fixContract.engagedMonthlyFinancedAmount.label" /></b>
        </Grid>
        <Grid item xs={6}>
          <AmountFormatterComponent amount={props.fixContract.engagedMonthlyFinancedAmount} />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <b><IntlMessageComponent id="page.fixContract.engagedavginvoiceamount.label" /></b>
        </Grid>
        <Grid item xs={6}>
          <AmountFormatterComponent amount={props.fixContract.engagedAvgInvoiceAmount} />
        </Grid>
      </Grid>

      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <b><IntlMessageComponent id="page.fixContract.engagedminservicefee.label" /></b>
        </Grid>
        <Grid item xs={6}>
          <AmountFormatterComponent amount={props.fixContract.engagedMinServiceFee} />
        </Grid>
      </Grid>

      {/* <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6}>
          <b><IntlMessageComponent id="page.fixContract.realizedperiod.label" /></b>
        </Grid>
        <Grid item xs={6}>
          <NumberFormatterComponent value={props.fixContract.realizedPeriodInMonths} />
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
}
