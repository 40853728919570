/* @flow */

import React from 'react';
import moment from 'moment';

import IntlMessageComponent from 'components/formatters/intlMessage';
import DatePickerComponent from 'components/formFields/dates/datePicker';

type BuyerInvoiceYearMonthFilterComponentProps = {
  fullWidth: boolean;
  margin: string;
  value: string;
  changeHandler: (date: moment) => void;
}

export default function BuyerInvoiceYearMonthFilterComponent(props: BuyerInvoiceYearMonthFilterComponentProps) {

  const handleYearChange = (value: moment) => {
    return props.changeHandler(value);
  };

  return (
    <DatePickerComponent
      autoOk
      fullWidth
      maxDate={moment()}
      minDate={moment("2010-01-01")}
      value={props.value}
      onChange={handleYearChange}
      clearable={true}
      clearLabel={<IntlMessageComponent id="component.periodpicker.removefilter" />}
    />
  );
}
