/* @flow */

import React, { useState } from 'react';
import moment from 'moment';

import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Download from '@material-ui/icons/CloudDownload';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';

import ButtonComponent from 'components/buttons/button';
import FileExportDialogComponent from 'components/fileExportDialog';
import IntlMessageComponent from 'components/formatters/intlMessage';

import InvoiceDateFilterComponent from './invoiceDate';
import InvoicePeriodFilterComponent from './invoicePeriod';
import InvoiceStateFilterComponent from './invoiceState';
import InvoiceStatusFilterComponent from './invoiceStatus';
import BuyerInvoiceYearMonthFilterComponent from './invoiceYearMonth';
import BuyerPaymentReportFilterComponent from './paymentReportDate';

import InvoiceActiveFilters from 'models/buyers/invoices/invoiceActiveFilters';
import InvoiceFilters from 'models/buyers/invoices/invoiceFilters';

import SecurityComponent from 'components/security/index';
import PageSubTitleViewComponent from 'components/pageTitle/subTitle';

type BuyerInvoiceTableControlsComponentProps = {
  activeFilters: InvoiceActiveFilters;
  filterOnInvoiceStatus: boolean;
  filterOnPeriod: boolean;
  filters: InvoiceFilters;
  customPeriodShown: boolean;
  handleAccountingExport: (format: string) => void;
  handleExport: (format: string) => void;
  handlePaymentExport: (format: string) => void;
  handleFiltersChange: (filters: InvoiceActiveFilters) => void;
  onYearMonthChange: (date: moment) => void;
}

export default function BuyerInvoiceTableControlsComponent(props: BuyerInvoiceTableControlsComponentProps) {

  const [showExportPopUp, setShowExportPopUp] = useState(false);
  const [showAccountingExportPopUp, setShowAccountingExportPopUp] = useState(false);
  const [showPaymentExportPopUp, setShowPaymentExportPopUp] = useState(false);

  const handlePortfolioExport = (format) => {
    setShowExportPopUp(false);
    props.handleExport(format);
  }

  const handleAccountingExport = (format) => {
    setShowAccountingExportPopUp(false);
    props.handleAccountingExport(format);
  }

  const handlePaymentExport = (format) => {
    setShowPaymentExportPopUp(false);
    props.handlePaymentExport(format);
  }

  const handleDateChange = (date: string) => {
    const activeFilters = props.activeFilters;
    activeFilters.date = date;
    props.handleFiltersChange(activeFilters);
  }

  const handleQueryChange = (event) => {
    const activeFilters = props.activeFilters;
    activeFilters.query = event.target.value;
    props.handleFiltersChange(activeFilters);
  };

  const handleStateChange = (state: string) => {
    const activeFilters = props.activeFilters;
    activeFilters.state = state;
    activeFilters.statusKey = '';
    props.handleFiltersChange(activeFilters);
  };

  const handleStatusChange = (statusKey: string) => {
    const activeFilters = props.activeFilters;
    activeFilters.state = '';
    activeFilters.statusKey = statusKey;
    props.handleFiltersChange(activeFilters);
  };

  const handleClosedPeriodChange = (dateFrom: moment, dateTo: moment) => {
    const activeFilters = props.activeFilters;
    activeFilters.closedDateFrom = dateFrom;
    activeFilters.closedDateTo = dateTo;
    props.handleFiltersChange(activeFilters);
  };

  const handlePurchasedPeriodChange = (dateFrom: moment, dateTo: moment) => {
    const activeFilters = props.activeFilters;
    activeFilters.purchasedDateFrom = dateFrom;
    activeFilters.purchasedDateTo = dateTo;
    props.handleFiltersChange(activeFilters);
  };

  const handleYearMonthChange = (date: moment) => {
    if (date === null)
      date = moment();
    const activeFilters = props.activeFilters;
    activeFilters.yearMonth = date;
    props.handleFiltersChange(activeFilters);
    props.onYearMonthChange(date);
  }


  const handlePaymentReportDateFromChange = (date: moment) => {
    const activeFilters = props.activeFilters;
    activeFilters.paymentReportDateFrom = date;
    props.handleFiltersChange(activeFilters);
  }

  const handlePaymentReportDateToChange = (date: moment) => {
    const activeFilters = props.activeFilters;
    activeFilters.paymentReportDateTo = date;
    props.handleFiltersChange(activeFilters);
  }

  const labelColor = "text.primary";

  return (
    <React.Fragment>
      <Box pb={2}>
        <Alert severity="info">
          <IntlMessageComponent id="buyer.portfolio.yearsshown" />
        </Alert>
      </Box>
      <Paper elevation={0}>

        <Box p={3} py={4}>
          <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
            <Grid container direction="column" item xs={12} md={6}>
              <Grid item xs={12} md={6}>
                <Box display="flex" height="100%" alignItems="center">
                  <FormLabel component="legend" >
                    <b><IntlMessageComponent id="buyer.portfolio.filter.search" /></b>
                    <span style={{ paddingLeft: "0.5rem" }}><IntlMessageComponent id="component.portfolio.filter.search.help" /></span>
                  </FormLabel>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  value={props.activeFilters.query}
                  onChange={handleQueryChange}
                  margin="dense"
                  fullWidth
                />
              </Grid>
            </Grid>


          </Grid>

          <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>
            <Grid container item xs={12} md={6}>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Box pb={1}>
                  <PageSubTitleViewComponent id="buyer.portfolio.filters.snapshot" />
                </Box>
              </Grid>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Box pt={2} pl={2} pr={2} bgcolor="#F7F7F7" width="100%">
                  <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <Grid container direction="column" item xs={12} md={6}>
                      <Box pr={1}>
                        <Grid item>
                          <FormLabel component="legend" >
                            <b><IntlMessageComponent id="buyer.portfolio.filter.state" /></b>
                          </FormLabel>
                        </Grid>

                        <Grid item>
                          <InvoiceStateFilterComponent
                            disabled={props.activeFilters.statusKey}
                            value={props.activeFilters.state}
                            changeHandler={handleStateChange}
                            margin="dense"
                            fullWidth
                          />
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid container direction="column" item xs={12} md={6}>
                      <Box pl={1}>
                        <Grid item>
                          <FormLabel component="legend" >
                            <b><IntlMessageComponent id="buyer.portfolio.filter.status" /></b>
                          </FormLabel>
                        </Grid>

                        <Grid item>
                          <InvoiceStatusFilterComponent
                            disabled={props.activeFilters.state}
                            value={props.activeFilters.statusKey}
                            values={props.filters.statusKeys}
                            changeHandler={handleStatusChange}
                            margin="dense"
                            fullWidth
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Box pb={2} pl={2} pr={2} bgcolor="#F7F7F7" width="100%">
                  <Grid container direction="row" justify="space-between" alignItems="flex-start">
                    <Grid container direction="column">
                      <Box pt={2}>
                        <Grid item xs={12} md={6}>
                          <Box display="flex" height="100%" alignItems="center" pb={1.1}>
                            <FormLabel component="legend" >
                              <b><IntlMessageComponent id="buyer.portfolio.filter.snapshotdate" /></b>
                            </FormLabel>
                          </Box>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <BuyerInvoiceYearMonthFilterComponent
                            value={props.activeFilters.yearMonth}
                            changeHandler={handleYearMonthChange}
                            margin="dense"
                            fullWidth
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>

                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6}>
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Box pb={1}>
                  <PageSubTitleViewComponent id="buyer.portfolio.filters.reportdate" />
                </Box>
              </Grid>
              <Box p={2} bgcolor="#F7F7F7" width="100%">
                <Grid container item xs={12} md={6}>
                  <Grid item xs={12}>
                    <FormLabel component="legend" >
                      <b><IntlMessageComponent id="buyer.portfolio.filter.closeddate" /></b>
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" height="100%" alignItems="center">
                      <InvoicePeriodFilterComponent
                        dateFrom={props.activeFilters.closedDateFrom}
                        dateTo={props.activeFilters.closedDateTo}
                        changeHandler={handleClosedPeriodChange}
                        margin="dense"
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container item xs={12} md={6}>
                  <Box pt={2} width="100%">
                    <Grid item xs={12}>
                      <FormLabel component="legend" >
                        <b><IntlMessageComponent id="buyer.portfolio.filter.purchaseddate" /></b>
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" height="100%" alignItems="center">
                        <InvoicePeriodFilterComponent
                          dateFrom={props.activeFilters.purchasedDateFrom}
                          dateTo={props.activeFilters.purchasedDateTo}
                          changeHandler={handlePurchasedPeriodChange}
                          margin="dense"
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid container direction="row" justify="space-between" alignItems="flex-start" spacing={3}>


          </Grid>

          <Box mt={5}>
            <SecurityComponent pageName="POS.Buyers.Portfolio" elementName="btnExport">
              <ButtonComponent
                color="primary"
                variant="contained"
                startIcon={<Download />}
                onClick={() => setShowExportPopUp(true)}
                data-walkme="sportfolio-button-exporter"
              >
                <IntlMessageComponent id="buyer.portfolio.btn.export" />
              </ButtonComponent>
            </SecurityComponent>

            <Box component="span" ml={1}>
              <SecurityComponent pageName="POS.Buyers.Portfolio" elementName="btnAccountingExport">
                <ButtonComponent
                  color="primary"
                  variant="contained"
                  startIcon={<Download />}
                  onClick={() => setShowAccountingExportPopUp(true)}
                  data-walkme="sportfolio-button-accountingExporter"
                >
                  <IntlMessageComponent id="buyer.portfolio.btn.exportAccouting" />
                </ButtonComponent>
              </SecurityComponent>
            </Box>
          </Box>
        </Box>

        {showExportPopUp &&
          <FileExportDialogComponent
            isOpen
            typesAllowed={['xlsx', 'csv']}
            handleExport={(format: string) => handlePortfolioExport(format)}
            handleClose={() => setShowExportPopUp(false)}
          />
        }

        {showAccountingExportPopUp &&
          <FileExportDialogComponent
            isOpen
            typesAllowed={['xlsx', 'csv']}
            handleExport={(format: string) => handleAccountingExport(format)}
            handleClose={() => setShowAccountingExportPopUp(false)}
          />
        }
        {showPaymentExportPopUp &&
          <FileExportDialogComponent
            isOpen
            typesAllowed={['xlsx']}
            handleExport={(format: string) => handlePaymentExport(format)}
            handleClose={() => setShowPaymentExportPopUp(false)}
          />
        }
      </Paper>


      <Paper elevation={0}>
        <Box p={3} py={4} mt={5}>
          <Box pb={1}>
            <PageSubTitleViewComponent id="buyer.portfolio.filter.payment.title" />
          </Box>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Box pb={2} pl={2} pr={2} bgcolor="#F7F7F7" width="49%">
              <Grid container direction="row" justify="space-between" alignItems="flex-start">
                <Grid item xs={6}>
                  <Grid container direction="column">
                    <Box pr={1} pt={2}>
                      <Grid item>
                        <Box display="flex" height="100%" alignItems="center" pb={1.1}>
                          <FormLabel component="legend" >
                            <b><IntlMessageComponent id="buyer.portfolio.filter.payment.datefrom" /></b>
                          </FormLabel>
                        </Box>
                      </Grid>

                      <Grid item>
                        <BuyerPaymentReportFilterComponent
                          value={props.activeFilters.paymentReportDateFrom}
                          changeHandler={handlePaymentReportDateFromChange}
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column">
                    <Box pl={1} pt={2}>
                      <Grid item>
                        <Box display="flex" height="100%" alignItems="center" pb={1.1}>
                          <FormLabel component="legend" >
                            <b><IntlMessageComponent id="buyer.portfolio.filter.payment.dateto" /></b>
                          </FormLabel>
                        </Box>
                      </Grid>

                      <Grid item>
                        <BuyerPaymentReportFilterComponent
                          value={props.activeFilters.paymentReportDateTo}
                          changeHandler={handlePaymentReportDateToChange}
                          margin="dense"
                          fullWidth
                          minDate={props.activeFilters.paymentReportDateFrom}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Box mt={5}>
            <SecurityComponent pageName="POS.Buyers.Portfolio" elementName="btnAccountingExport">
              <ButtonComponent
                color="primary"
                variant="contained"
                startIcon={<Download />}
                onClick={() => setShowPaymentExportPopUp(true)}
                data-walkme="sportfolio-button-accountingExporter"
                disabled={props.activeFilters.paymentReportDateTo < props.activeFilters.paymentReportDateFrom}
              >
                <IntlMessageComponent id="buyer.portfolio.btn.exportPayment" />
              </ButtonComponent>
            </SecurityComponent>
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
