/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import LoadingComponent from 'components/loading';

import CompanyFixContractViewComponent from './view';
import FixContractTableComponent from './table';

import CompanyFixContract from 'models/companies/companyFixContract';

import CompanyService from 'services/CompanyService';
import DocumentService from 'services/DocumentService';

import NotificationHelper from 'lib/notifications';

type CompanyFixContractComponentProps = {
  activeCompanyId: number;
  isActive: boolean;
  isCompliant: boolean;
  isConfirming: boolean;
}

type CompanyFixContractComponentState = {
  isLoading: boolean;
  isProcessing: boolean;
  fixContracts: CompanyFixContract[];
  selectedFixContract: CompanyFixContract;
}

class CompanyFixContractComponent extends React.Component<CompanyFixContractComponentProps, CompanyFixContractComponentState> {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isProcessing: false,
      fixContracts: null
    };
  }

  componentDidMount = async () => {
    this.loadfixContracts();
  };

  downloadDocument = async (documentId: number) => {
    try {
      await DocumentService.downloadCompanyDocument(this.props.activeCompanyId, documentId);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  selectContract = async (contract: CompanyFixContract) => {
    this.setState({ isLoading: false, fixContracts: this.state.fixContracts, selectedFixContract: contract })
  }

  backToSelection = async () => {
    this.setState({ isLoading: false, fixContracts: this.state.fixContracts, selectedFixContract: undefined })
  }

  loadfixContracts = async () => {
    try {
      const fixContracts = await CompanyService.getFixContracts(this.props.activeCompanyId);
      if (fixContracts.length === 1) {
        this.setState({ isLoading: false, fixContracts, selectedFixContract: fixContracts[0] })
      }
      else {
        this.setState({ isLoading: false, fixContracts });
      }
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    if (this.state.selectedFixContract) {
      return (
        <CompanyFixContractViewComponent
          fixContract={this.state.selectedFixContract}
          downloadDocument={this.downloadDocument}
          showBackButton={this.state.fixContracts.length > 1}
          backToSelection={this.backToSelection}
          isConfirming={this.props.isConfirming}
        />
      );
    }
    else {
      return (
        <FixContractTableComponent
          fixContracts={this.state.fixContracts}
          selectContract={this.selectContract}
          isConfirming={this.props.isConfirming}
        />
      )
    }
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  isActive: state.auth.user.activeCompany.isActive,
  isCompliant: state.auth.user.activeCompany.isCompliant,
  isConfirming: state.auth.user.activeCompany.isConfirming
});

export default connect(mapStateToProps)(CompanyFixContractComponent);
