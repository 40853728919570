/* @flow */

import moment from 'moment';

export default class InvoiceActiveFilters {
  date: string;
  closedDateFrom: moment;
  closedDateTo: moment;
  purchasedDateFrom: moment;
  purchasedDateTo: moment; 
  query: string;
  state: string;
  statusKey: string;  
  yearMonth: moment;
  paymentReportDateFrom: moment;
  paymentReportDateTo: moment;

  constructor() {
    this.date = '';
    this.closedDateFrom = null;
    this.closedDateTo = null;
    this.purchasedDateFrom = null;
    this.purchasedDateTo = null;
    this.query = '';
    this.state = '';
    this.statusKey = '';
    this.yearMonth = moment();
    this.paymentReportDateFrom = null;
    this.paymentReportDateTo = null;
  }
}
