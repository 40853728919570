/* @flow */

import {
  PROTECTED_COMPANY,
  PROTECTED_COMPANY_ADD,
  PROTECTED_COMPANY_DOCUMENTS,
  PROTECTED_COMPANY_FIXCONTRACT,
  PROTECTED_COMPANY_OFFICES,
  PROTECTED_COMPANY_SUBSCRIPTIONS,
  PROTECTED_COMPANY_UBOS,
  PROTECTED_COMPANY_USERS,
  PROTECTED_COMPANY_BANKACCOUNTS,
  PROTECTED_COMPANY_FIXCONTRACT_CONFIRMING
} from 'constants/pageRoutes';

import { ROLE_BUYER, ROLE_CONFIRMING, ROLE_DEBTOR, ROLE_ISO, ROLE_SELLER } from 'constants/companyRoles';

export const companyMenuLinksData = [
  {
    id: 1,
    link: PROTECTED_COMPANY,
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuCompanyInfo',
    translationKey: 'header.company.infos'
  },
  {
    id: 2,
    link: PROTECTED_COMPANY_BANKACCOUNTS,
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuCompanyInfo',
    translationKey: 'header.company.bankaccounts'
  },
  {
    id: 3,
    link: PROTECTED_COMPANY_ADD,
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuAddCompany',
    translationKey: 'header.company.add'
  },
  {
    id: 4,
    link: PROTECTED_COMPANY_SUBSCRIPTIONS,
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuMembership',
    translationKey: 'header.company.subscriptions',
    visibleBy: [ROLE_BUYER, ROLE_SELLER]
  },
  {
    id: 5,
    link: PROTECTED_COMPANY_UBOS,
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuUbos',
    translationKey: 'header.company.ubos',
    visibleBy: [ROLE_BUYER, ROLE_SELLER]
  },
  {
    id: 6,
    link: PROTECTED_COMPANY_OFFICES,
    translationKey: 'header.company.offices',
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuOffices',
    visibleBy: [ROLE_DEBTOR, ROLE_ISO]
  },
  {
    id: 7,
    link: PROTECTED_COMPANY_USERS,
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuUsers',
    translationKey: 'header.company.users'
  },
  {
    id: 8,
    link: PROTECTED_COMPANY_DOCUMENTS,
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuDocuments',
    translationKey: 'header.company.documents',
    visibleBy: [ROLE_BUYER, ROLE_SELLER, ROLE_DEBTOR, ROLE_ISO]
  },
  {
    id: 9,
    link: PROTECTED_COMPANY_FIXCONTRACT,
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuFixcontract',
    translationKey: 'header.company.fixcontract',
    visibleBy: [ROLE_SELLER],
    needsFixContract: true
  },
  {
    id: 10,
    link: PROTECTED_COMPANY_FIXCONTRACT_CONFIRMING,
    pageName: 'POS.CompanyMenu',
    elementName: 'mnuDocuments',
    translationKey: 'header.company.fixcontractconfirming',
    visibleBy: [ROLE_CONFIRMING],
    needsFixContract: true
  }
];
