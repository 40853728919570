/* @flow */
import React from 'react';
import moment from 'moment';

import { connect } from 'react-redux';
import { setComponentData } from 'store/actions/componentDataActions';

import customSort from 'lib/sortHelpers';
import NotificationHelper from 'lib/notifications';

import LoadingComponent from 'components/loading';

import BuyerInvoicesPortfolioControlsComponent from './controls/index';
import BuyerInvoicesPortfolioTableViewComponent from './view';
import BuyerInvoicesPortfolioExportViewComponent from './export';

import DownloadAccountingRequest from 'models/requests/buyers/downloadAccountingRequest';
import DownloadPortfolioRequest from 'models/requests/buyers/downloadPortfolioRequest';
import DownloadPaymentReportRequest from 'models/requests/buyers/downloadPaymentReportRequest';
import Invoice from 'models/buyers/invoices/invoice';
import InvoiceActiveFilters from 'models/buyers/invoices/invoiceActiveFilters';
import InvoiceFilters from 'models/buyers/invoices/invoiceFilters';

import BuyerService from 'services/BuyerService';
import DocumentService from 'services/DocumentService';

import {
  INVOICE_STATE_FILTER_OPEN,
  INVOICE_STATE_FILTER_CLOSED
} from 'constants/constants';

type BuyerInvoicesPortfolioTableComponentProps = {
  activeCompanyId: number;
  activeCompanyInvestmentProfileId: number;
  activeFilters: InvoiceActiveFilters;

  showInvestmentProfile: boolean;
}

type BuyerInvoicesPortfolioTableComponentState = {
  filters: InvoiceFilters;
  invoices: Invoice[];
  documents: DocumentItem[];
  selectedDocument: DocumentItem;
  selectedDocumentDate: moment;
  documentsEarliestDate: moment;
  isLoading: boolean;
  order: string;
  orderBy: ?string;
  page: number;
  pageSize: number;
}

class BuyerInvoicesPortfolioTableComponent extends React.Component<BuyerInvoicesPortfolioTableComponentProps, BuyerInvoicesPortfolioTableComponentState> {

  constructor(props) {
    super(props);
    this.state = {
      filters: new InvoiceActiveFilters(),
      invoices: [],
      documents: null,
      isLoading: true,
      order: 'asc',
      orderBy: undefined,
      page: 0,
      pageSize: 10
    };
  }

  componentDidMount = async () => {
    try {
      const documents = await DocumentService.getCompanyPortfolioDocuments(this.props.activeCompanyId);
      const selectedDocument = documents.documents.find(d => moment(d.upload).isSame(moment().subtract(1, 'days'), 'day'));
      const sortedDocuments = documents.documents.sort((a, b) => moment(a.upload).format('YYYYMMDD') - moment(b.upload).format('YYYYMMDD'));
      const documentsEarliestDate = sortedDocuments.length > 0 ? sortedDocuments[0].upload : moment();
      if (this.props.activeFilters.yearMonth === null) {
        const { filters, invoices } = await BuyerService.getInvoicesPortfolio(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId);
        this.setState({ filters, invoices, documents, selectedDocument, documentsEarliestDate, selectedDocumentDate: moment().subtract(1, 'days'), isLoading: false });
      } else {
        const { filters, invoices } = await BuyerService.getInvoicesPortfolioOnDate(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, this.props.activeFilters.yearMonth);
        this.setState({ filters, invoices, documents, selectedDocument, documentsEarliestDate, selectedDocumentDate: moment().subtract(1, 'days'), isLoading: false });
      }
    } catch (e) {
      console.error(e);
    }
  }

  onYearMonthChange = async (date: moment) => {
    this.setState({ isLoading: true });
    try {
      if (date === null) {
        const { invoices } = await BuyerService.getInvoicesPortfolio(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId);
        this.setState({ invoices, isLoading: false });
      } else {
        const { invoices } = await BuyerService.getInvoicesPortfolioOnDate(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, date);
        this.setState({ invoices, isLoading: false });
      }
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  downloadDocument = async (documentId: number) => {
    try {
      await DocumentService.downloadCompanyDocument(this.props.activeCompanyId, documentId);
    } catch (e) {
      console.error(e);
      NotificationHelper.createNotification(NotificationHelper.TYPE_ERROR);
    }
  }

  documentDateChange = (selectedDocumentDate: moment) => {
    try {
      const selectedDocument = this.state.documents.documents.find(d => moment(d.upload).isSame(selectedDocumentDate, 'day'));
      this.setState({ selectedDocument, selectedDocumentDate });
    } catch (e) {
      console.error(e);
    }
  }

  handlePaymentExport = async (format: string) => {
    try {
      const request = new DownloadPaymentReportRequest(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, format, this.props.activeFilters);
      await BuyerService.downloadPayment(request);
    } catch (e) {
      console.error(e);
    }
  };

  handleAccountingExport = async (format: string) => {
    try {
      const request = new DownloadAccountingRequest(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, format);
      await BuyerService.downloadAccounting(request);
    } catch (e) {
      console.error(e);
    }
  };

  handleExport = async (format: string) => {
    try {
      const request = new DownloadPortfolioRequest(this.props.activeCompanyId, this.props.activeCompanyInvestmentProfileId, format, this.props.activeFilters);
      await BuyerService.downloadPortfolio(request);
    } catch (e) {
      console.error(e);
    }
  };

  handleFiltersChange = (activeFilters: InvoiceActiveFilters) => {
    const clone = Object.assign({}, activeFilters);
    this.props.setComponentData('buyerInvoicesPortfolioTable', { activeFilters: clone });
  }

  handlePageChange = (page: number) => this.setState({ page });
  handlePageSizeChange = (pageSize: number) => this.setState({ pageSize });

  handleSortOrderChange = (orderBy: string) => {
    let order = this.state.orderBy === orderBy && this.state.order === 'desc' ? 'asc' : 'desc';

    let invoices = null;
    if (orderBy.startsWith('debtorCompany.')) {
      // get exact property name
      let o = orderBy.substring(14);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.debtorCompany[o], a.debtorCompany[o]))
        : this.state.invoices.sort((a, b) => customSort(a.debtorCompany[o], b.debtorCompany[o]));
    }
    else if (orderBy.startsWith('investmentProfile.')) {
      // get exact property name
      let o = orderBy.substring(18);

      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b.investmentProfile[o], a.investmentProfile[o]))
        : this.state.invoices.sort((a, b) => customSort(a.investmentProfile[o], b.investmentProfile[o]));
    }
    else {
      // order
      invoices = order === 'desc'
        ? this.state.invoices.sort((a, b) => customSort(b[orderBy], a[orderBy]))
        : this.state.invoices.sort((a, b) => customSort(a[orderBy], b[orderBy]));
    }

    this.setState({ invoices, order, orderBy });
  };

  getFilteredResults = () => {
    let filteredInvoices = this.state.invoices;

    if (this.props.activeFilters.query && this.props.activeFilters.query.length > 2) {
      filteredInvoices = filteredInvoices.filter((i: Invoice) => {
        const query = this.props.activeFilters.query.toUpperCase();
        const fields = [i.id, i.invoiceName, i.debtorCompany.name, i.paymentReference].map(v => (v || '').toString().toUpperCase());
        return !!(fields.filter(field => field.toString().indexOf(query) !== -1)).length;
      });
    }

    // If filter "Open Invoice", exclude all closed invoices
    if (this.props.activeFilters.state === INVOICE_STATE_FILTER_OPEN) {
      filteredInvoices = filteredInvoices.filter((i: Invoice) => !i.closedDate);
    } else if (this.props.activeFilters.state === INVOICE_STATE_FILTER_CLOSED) {
      filteredInvoices = filteredInvoices.filter((i: Invoice) => i.closedDate);
    }

    // Filter on invoice status
    if (this.props.activeFilters.statusKey !== '') {
      filteredInvoices = filteredInvoices.filter(i => i.statusKey.toUpperCase() === this.props.activeFilters.statusKey.toUpperCase());
    }

    if (this.props.activeFilters.closedDateFrom !== null && this.props.activeFilters.closedDateTo !== null) {
      filteredInvoices = filteredInvoices.filter(i => {
        const filterDate = moment(i.closedDate);
        return filterDate.isBetween(this.props.activeFilters.closedDateFrom, this.props.activeFilters.closedDateTo, 'day', '[]');
      });
    }

    if (this.props.activeFilters.purchasedDateFrom !== null && this.props.activeFilters.purchasedDateTo !== null) {
      filteredInvoices = filteredInvoices.filter(i => {
        const filterDate = moment(i.purchasedDate);
        return filterDate.isBetween(this.props.activeFilters.purchasedDateFrom, this.props.activeFilters.purchasedDateTo, 'day', '[]');
      });
    }

    return filteredInvoices;
  };

  render() {
    if (this.state.isLoading) {
      return (<LoadingComponent />);
    }

    return (
      <React.Fragment>

        <BuyerInvoicesPortfolioControlsComponent
          activeFilters={this.props.activeFilters}
          filters={this.state.filters}
          handleAccountingExport={this.handleAccountingExport}
          handleExport={this.handleExport}
          handleFiltersChange={this.handleFiltersChange}
          handlePaymentExport={this.handlePaymentExport}
          onYearMonthChange={this.onYearMonthChange}
        />

        <BuyerInvoicesPortfolioTableViewComponent
          invoices={this.getFilteredResults()}
          order={this.state.order}
          orderBy={this.state.orderBy}
          page={this.state.page}
          pageSize={this.state.pageSize}
          showConfirming={this.getFilteredResults().filter(i => i.isConfirming === true).length === this.getFilteredResults().length}
          showInvestmentProfile={this.props.showInvestmentProfile}
          handlePageChange={this.handlePageChange}
          handlePageSizeChange={this.handlePageSizeChange}
          handleSortOrderChange={this.handleSortOrderChange}
        />

        {//component only available for company H-Securitisation
          this.props.activeCompanyId === 69136 && <BuyerInvoicesPortfolioExportViewComponent
            documents={this.state.documents}
            selectedDocument={this.state.selectedDocument}
            selectedDocumentDate={this.state.selectedDocumentDate}
            documentsEarliestDate={this.state.documentsEarliestDate}
            downloadDocument={this.downloadDocument}
            documentDateChange={this.documentDateChange}
          />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyId: state.auth.user.activeCompany.id,
  activeCompanyInvestmentProfileId: state.auth.user.activeCompany.investmentProfile.id,
  activeFilters: state.components.buyerInvoicesPortfolioTable.activeFilters,
  showInvestmentProfile: state.auth.user.activeCompany.investmentProfile.id === 0 && state.auth.user.activeCompany.roleBuyer.investmentProfiles.length > 1
});

export default connect(mapStateToProps, { setComponentData })(BuyerInvoicesPortfolioTableComponent);
