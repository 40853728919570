/* @flow */

import Company from 'models/companies/company';

import AddNewCompanyRequest from 'models/requests/companies/addNewCompanyRequest';

import {
  ROLE_BUYER,
  ROLE_DEBTOR,
  ROLE_ISO,
  ROLE_CONFIRMING
} from 'constants/companyRoles';

import {
  COMPANY_TYPE_BUYER,
  COMPANY_TYPE_DEBTOR,
  COMPANY_TYPE_ISO,
  COMPANY_TYPE_SELLER,
  COMPANY_TYPE_CONFIRMING
} from 'constants/constants';

export default class AddNewCompanyForm {
  companyType: number;
  company: Company;
  functionName: string;
  mobile: string;

  constructor(companyRole: number) {
    switch (companyRole) {
      case ROLE_BUYER:
        this.companyType = COMPANY_TYPE_BUYER;
        break;
      case ROLE_DEBTOR:
        this.companyType = COMPANY_TYPE_DEBTOR;
        break;
      case ROLE_ISO:
        this.companyType = COMPANY_TYPE_ISO;
        break;
      case ROLE_CONFIRMING:
        this.companyType = COMPANY_TYPE_CONFIRMING;
        break;
      default:
        this.companyType = COMPANY_TYPE_SELLER;
        break;
    }

    this.company = null;
    this.functionName = '';
    this.mobile = '';
  }

  toRequest() {
    const request = new AddNewCompanyRequest();
    request.companyType = this.companyType;
    request.insurerId = this.company.insurerId;
    request.functionName = this.functionName;
    request.mobile = this.mobile;
    return request;
  }
}
